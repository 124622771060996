import Section from './Section'
import Heading from '../components/Heading'

export default function Info() {
  return (
    <Section lgNoMargin className="lg:order-last w-full xs:w-3/4">
      <Box>
        <Heading size={4}>Contactgegevens</Heading>
        <Section small>
          <List title="EDI-Central B.V.">
            <div className="block">Stationsweg 28A, 7429AD Colmschate</div>
            <div className="flex items-center">
              <Mail />
              <a
                className="block text-blue-700 underline hover:text-blue-800"
                href="mailto:i&#110;&#102;&#111;&#64;edi&#45;&#99;en&#116;&#114;&#97;&#108;.n&#108;"
              >
                i&#110;&#102;&#111;&#64;edi&#45;&#99;en&#116;&#114;&#97;&#108;.n&#108;
              </a>
            </div>
            <div className="flex items-center">
              <Phone />
              <a
                className="flex items-center text-blue-700 underline hover:text-blue-800"
                href="tel:0031851308458"
              >
                +31 85 13 08 458
              </a>
            </div>
          </List>
        </Section>
        <List title="Voor ondersteuning">
          <div className="flex items-center">
            <Mail />
            <a
              className="block text-blue-700 underline hover:text-blue-800"
              href="mailto:s&#117;&#112;&#112;&#111;&#114;&#116;&#64;edi&#45;&#99;en&#116;&#114;&#97;&#108;.n&#108;"
            >
              s&#117;&#112;&#112;&#111;&#114;&#116;&#64;edi&#45;&#99;en&#116;&#114;&#97;&#108;.n&#108;
            </a>
          </div>
          <div className="flex items-center">
            <Phone />
            <a
              className="flex items-center text-blue-700 underline hover:text-blue-800"
              href="tel:0031851308458"
            >
              +31 85 13 08 458
            </a>
          </div>
        </List>
      </Box>
    </Section>
  )
}

const List = ({ children, title }) => (
  <>
    <div className="mb-2 xs:mb-3">
      <Heading size={5} type={3} margin={false}>
        {title}
      </Heading>
    </div>
    <div className="space-y-3 leading-tight">{children}</div>
  </>
)

const Box = ({ children }) => (
  <Section
    small
    className="lg:ml-12 xl:ml-16 bg-white shadow-xl p-6 xs:p-8 lg:p-12 rounded-xl"
  >
    {children}
  </Section>
)

const Phone = () => (
  <svg
    className="w-4 h-4 flex-none mr-2.5"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
  </svg>
)

const Mail = () => (
  <svg
    className="w-4 h-4 flex-none mr-2.5"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
    <polyline points="22,6 12,13 2,6"></polyline>
  </svg>
)

const Home = () => (
  <svg
    className="w-4 h-4 flex-none mr-2.5"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </svg>
)

const Site = () => (
  <svg
    className="w-4 h-4 flex-none mr-2.5"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="2" y1="12" x2="22" y2="12"></line>
    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
  </svg>
)
